<template>
  <div class="container text-center">

    <div v-if="isFetching">
      <b-spinner variant="primary"></b-spinner>


    </div>

    <div v-else>
      <div :class="{'text-danger': errorMsg, 'text-success': successMsg}">
        {{successMsg || errorMsg}}
      </div>
      <br/>
      <router-link class="entry-link" :to="{ name: 'sign-in' }">Login</router-link>
    </div>
  </div>
</template>

<script>
  import {GOOGLE_SIGNIN} from "@/store/modules/auth";

  export default {
    name: 'SignInGoogle',
    data() {
      return {
        isFetching: true,
        successMsg: null,
        errorMsg: null,
      }
    },
    async mounted() {
      console.log('router', this.$route)
      try {
        const {data} = await this.$store.getters.apiService.sendGoogleAuthCode(this.$route.query.code)
        // this.$store.getters.apiService.setGoogleAuthToken(data.token)
        // window.opener.postMessage(`GOOGLE_AUTH_JWT:${data.token}`, window.location.origin+'1')
        await this.$store.dispatch(GOOGLE_SIGNIN, {
          token: data.token
        })

        console.log('data', data)
        this.successMsg = 'Success'

      } catch (e) {
        console.error(e)
        this.errorMsg = 'Signup error. Try again.'
      }
      this.isFetching = false
    },
    methods: {
      closeWindow() {
        window.close()
      }
    }
  }
</script>
